<template>
  <div>
    <ContentBlock style="padding-bottom: 0px;">
      <div class="top">
        <div class="left" @click="handleUpload">
          <el-avatar shape="square" :size="60" v-if="$store.state.company.currentCompanyInfo.logo" :src="$store.state.company.currentCompanyInfo.logo"></el-avatar>
          <template v-else>{{ $store.state.company.currentCompanyInfo.name?$store.state.company.currentCompanyInfo.name.substring(0,2):'' }}</template>
        </div>
        <div class="right">
          <h3>{{$store.state.company.currentCompanyInfo.name}}</h3>
          <p v-if="['1'].includes($store.state.company.currentCompanyInfo.authenticationStatus)">企业未认证，认证后可维护企业档案，<span class="approveBtn" @click="goToCertification">前往认证&nbsp;&gt;</span></p>
          <p v-else>统一社会信用代码：{{ $store.state.company.currentCompanyInfo.certCode }}</p>
        </div>
        <!-- <div class="rightImg">
          <el-image :src="audit" fit="fill" v-if="['1'].includes(companyInfo.tenantEnteringRespDTO.auditStatus)" style="width:60px;height: 60px;"></el-image>
          <el-image :src="success" fit="fill" v-if="['2'].includes(companyInfo.tenantEnteringRespDTO.auditStatus)" style="width:60px;height: 60px;"></el-image>
          <el-image :src="fail" fit="fill" v-if="['3'].includes(companyInfo.tenantEnteringRespDTO.auditStatus)" style="width:60px;height: 60px;"></el-image>
          <el-image :src="waiting" fit="fill" v-if="['4'].includes(companyInfo.tenantEnteringRespDTO.auditStatus)" style="width:60px;height: 60px;"></el-image>
        </div> -->
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="Basic"></el-tab-pane>
        <el-tab-pane label="证件/材料信息" name="Material"></el-tab-pane>
        <el-tab-pane label="联系与结算信息" name="Contact"></el-tab-pane>
      </el-tabs>
    </ContentBlock>
    <component
      style="margin-top: 10px;min-height: calc(100vh - 420px);"
      :is="activeName"
      :id="$route.query.id"
      :currentInfo="currentInfo"
      @updatePage="getDetailInfo"
    >
    </component>
    <div style="visibility: hidden;">
      <Upload
        v-model="imageLists"
        label="附件信息"
        buttonText="上传"
        type="single"
        ref="JZUpload"
        :show-file-list="false"
        @success="handleFileLists"
        @remove="handleRemove"
        accept="image/png,image/jpeg,image/jpg,application/pdf"
        :tip="false"
        :maxSize="10"
        size="origin"
        :limit="2"
      >
      </Upload>
    </div>
    <AccreditationDialog ref="JZAccreditationDialog"></AccreditationDialog>
  </div>
</template>

<script>
import {getDetailTenantInformationInfo} from '@/api/systemSetting';
import AvaterMixin from './mixins/avater';
export default {
  name: '',
  mixins: [AvaterMixin],
  components: {
    Basic: () => import('./component/Basic'),
    Material: () => import('./component/Material/index.vue'),
    Contact: () => import('./component/Contact/index'),
  },
  created () {
    this.getDetailInfo();
  },
  methods: {
    handleClick ({name}) {
      this.activeName = name;
    },
    getDetailInfo () {
      getDetailTenantInformationInfo({
        type: '1',
        dataType: '1',
      }).then(({body}) => {
        this.currentInfo = body;
      });
    },
  },
  data () {
    return {
      currentInfo: {},
      companyInfo: {},
      currentCompanyInfo: {},
      imageLists: [],
      activeName: 'Basic',
      isComponent: 'Basic',
      audit: require('@/assets/company/auditing.png'),
      success: require('@/assets/company/success.png'),
      fail: require('@/assets/company/fail.png'),
      waiting: require('@/assets/company/waiting.png'),
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
/deep/ .top{
  display: flex;
  .left{
    width: 60px;
    height: 60px;
    background: #E3ECFF;
    border-radius: 4px;
    font-size: 18px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    color: #1A66FF;
    line-height: 60px;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 1px solid #dddddd;
  }
  .right{
    padding-left: 10px;
    h3{
      font-family: PingFangSC-Semibold;
      font-size: 18px;
      color: #1F2733;
      letter-spacing: 0;
      text-align: left;
      line-height: 32px;
      margin-bottom: 0;
      font-weight: 600;
    }
    p{
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #5F6A7A;
      letter-spacing: 0;
      text-align: left;
      line-height: 20px;
      margin-bottom: 0;
    }
  }
  .rightImg{
    flex: 1;
    text-align:right;
    padding-right:40px;
  }
}
.approveBtn {
  color:#237FFA;
  cursor: pointer;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  letter-spacing: 0;
  text-align: left;
}
/deep/.el-tabs__header{
  margin-bottom: 0;
  .el-tabs__item{
    height: auto;
    padding-bottom: 6px;
  }
}

/deep/.el-tabs__nav-wrap::after{
  display: none;
}
</style>
